$primary: rgb(32, 147, 153);
$primary-light: lighten($primary, 5%);

$theme-colors: (
  "primary": $primary,
);

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: white !important;

  &::before {
    background-color: $primary-light !important;
    border-color: $primary-light !important;
  }

  &:hover {
    border-color: $primary-light !important;
  }
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}

.btn-outline-primary {
  border-color: $primary !important;
  color: $primary !important;

  &::before {
    background-color: transparent !important;
    border-color: $primary-light !important;
  }

  &:hover {
    border-color: $primary-light !important;
    color: white !important;
  }

  &:active {
    border-color: $primary !important;
    color: $primary !important;
  }
}

.btn-outline-secondary {
  border-color: rgba(0, 0, 0, 0.2) !important;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  &::before {
    background-color: transparent !important;
  }
}
